<template>
  <UFormGroup label="Field name" name="name" required>
    <UInput
      v-model="state.name" maxlength="255" data-test="field-name-input" placeholder="Enter your field name"
      autofocus
    />
  </UFormGroup>
  <UFormGroup label="Description" name="description">
    <UInput
      v-model="state.description"
      placeholder="Describe how people should use this field"
    />
  </UFormGroup>
</template>

<script lang="ts" setup>
const state = defineModel({
  type: Object as PropType<{ name: string; description: string }>,
  required: true,
})
</script>
